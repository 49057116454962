@import '@/styles/_variable.scss';

.loading {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    padding: 25px 28px 20px 28px;
    background-color: #FFFBF8;
    .cf {
        width: 85px;
        height: 52px;
        background: url('/images/cf-for-eng.png') no-repeat center left;
        background-size: contain;
    }
    .cfKorea {
        width: 85px;
        height: 52px;
        background: url('/images/cf-for-korea.png') no-repeat center left;
        background-size: contain;
    }
    .cfChina {
        width: 85px;
        height: 52px;
        background: url('/images/cf-for-china.png') no-repeat center left;
        background-size: contain;
    }

    .euEn {
        width: 170px;
        height: 39px;
        background: url('/images/theEUPOS-en.png') no-repeat center right;
        background-size: contain;
    }
    .euKorea {
        width: 170px;
        height: 39px;
        background: url('/images/theEUPOS-korea.png') no-repeat center right;
        background-size: contain;
    }
    .euTaiwan {
        width: 170px;
        height: 39px;
        background: url('/images/theEUPOS-taiwan.png') no-repeat center right;
        background-size: contain;
    }
    .euChina {
        width: 170px;
        height: 39px;
        background: url('/images/theEUPOS-china.png') no-repeat center right;
        background-size: contain;
    }

    
    .eie {
        width: 90px;
        height: 155px;
        background: url('/images/enjoy-signature.png') no-repeat center;
        background-size: contain;
    }
    .ee {
        width: 272px;
        height: 103px;
        background: url('/images/loader-logo.png') no-repeat center;
        background-size: contain;
    }
    .top {
        flex-grow: 1;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .eie {
        width: 100%;
        height: 78px;
        background: url('/images/enjoy-signature.png') no-repeat center right;
        background-size: contain;
        left: 0;
        bottom: 0;
    }
    .bottom {
        text-align: center;
        h4 {
            font-family: $font-sm;
            font-weight: 400;
            color: #000;
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }
        p {
            font-size: 0.9rem;
        }
        .logos {
            margin-bottom: 1.5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
    