@import '@/styles/_variable.scss';

.landing {
    padding-top: 90px;
    min-height: 93vh;
    background: url('/images/landing.jpg') no-repeat center top;
    background-size: cover;

    .cnt {
        text-align: center;
        margin-bottom: 2em;
        p, .description {
            font-size: 1.1em;
        }

        h1 {
            font-size: 2em;
            margin-bottom: 0.7em;
            text-align: center;
            font-family: $font-sm;
            line-height: 1.3;
            letter-spacing: 0.1em;
            letter-spacing: 1px;
        }
    }

    .logolanding {
        display: flex;
        justify-content: center;
        margin-bottom: 3em;
    }

    nav {
        display: flex;
        flex-direction: column;
        gap: 1.4em;
        padding-bottom: 5.5em;
        a {
            font-size: 2.3em;
            color: $color-green;
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.15);
            text-decoration: none;
            font-family: $font-sm;
            padding-bottom: 0.4em;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            i {
                width: 1.2em;
                height: 1.2em;
                border-radius: 50%;
                background-color: $color-green;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                &:after {
                    font-size: 0.6em;
                    content: '';
                    width:  0.5em;
                    height: 1em;
                    background: url('/images/nav-arrow.svg') no-repeat center;
                    background-size: contain;
                }
            }
            &:nth-child(2) {
                color: $color-ping;
                i {
                    background-color: $color-ping;
                }
            }

            &:nth-child(3) {
                color: $color-blue;
                i {
                    background-color: $color-blue;
                }
            }

            &:nth-child(4) {
                color: $color-orange;
                i {
                    background-color: $color-orange;
                }
            }
        }
    }

    
}